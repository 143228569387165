"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChannelSelectItems = exports.getMasterDealerIds = exports.getChannelOptions = exports.getMasterDealerChannels = exports.getAssociatedMasterDealerChannels = exports.getChannels = exports.canSeeShopByCategory = exports.canSeeCoop = exports.getMasterDealerId = void 0;
var tslib_1 = require("tslib");
var interfaces_1 = require("@monorepo/interfaces");
var getMasterDealerId = function (masterDealerId, channel) {
    if (masterDealerId === null || channel === null)
        return '';
    return "".concat(masterDealerId).concat(channel === null || channel === void 0 ? void 0 : channel.charAt(0));
};
exports.getMasterDealerId = getMasterDealerId;
var canSeeCoop = function (masterDealerId, channel, isDealerAdmin) {
    if (isDealerAdmin === void 0) { isDealerAdmin = false; }
    return (!!masterDealerId &&
        !!channel &&
        (masterDealerId !== '2252' ||
            (masterDealerId === '2252' && isDealerAdmin)) &&
        (masterDealerId !== '3550' ||
            (masterDealerId === '3550' && isDealerAdmin)) &&
        (masterDealerId !== '2251' ||
            (masterDealerId === '2251' && isDealerAdmin)) &&
        masterDealerId !== '9999999999' &&
        masterDealerId !== '9999999998' &&
        masterDealerId !== '9999999997' &&
        masterDealerId !== '9999999996' &&
        channel !== 'NR' &&
        channel !== 'ISP');
};
exports.canSeeCoop = canSeeCoop;
var canSeeShopByCategory = function (channel) {
    return channel !== 'ISP';
};
exports.canSeeShopByCategory = canSeeShopByCategory;
var getChannels = function (associatedMasterDealers) {
    var associatedMasterDealerChannels = (0, exports.getAssociatedMasterDealerChannels)(associatedMasterDealers);
    return associatedMasterDealerChannels.length
        ? associatedMasterDealerChannels
        : (0, exports.getMasterDealerChannels)(associatedMasterDealers);
};
exports.getChannels = getChannels;
var getAssociatedMasterDealerChannels = function (associatedMasterDealers) {
    return Array.isArray(associatedMasterDealers)
        ? Array.from(new Set(associatedMasterDealers.reduce(function (channels, associatedMasterDealer) {
            if (Array.isArray(associatedMasterDealer === null || associatedMasterDealer === void 0 ? void 0 : associatedMasterDealer.channels)) {
                return (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], channels, true), associatedMasterDealer.channels.filter(function (item) { return !!item; }), true);
            }
            else {
                return channels;
            }
        }, [])))
        : [];
};
exports.getAssociatedMasterDealerChannels = getAssociatedMasterDealerChannels;
var getMasterDealerChannels = function (associatedMasterDealers) {
    return Array.isArray(associatedMasterDealers)
        ? Array.from(new Set(associatedMasterDealers.reduce(function (channels, associatedMasterDealer) {
            if (Array.isArray(associatedMasterDealer === null || associatedMasterDealer === void 0 ? void 0 : associatedMasterDealer.masterDealer)) {
                return (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], channels, true), associatedMasterDealer.masterDealer.reduce(function (_channels_, masterDealer) {
                    if (Array.isArray(masterDealer === null || masterDealer === void 0 ? void 0 : masterDealer.channels)) {
                        return (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], _channels_, true), masterDealer.channels.filter(function (item) { return !!item; }), true);
                    }
                    else {
                        return _channels_;
                    }
                }, []), true);
            }
            else {
                return channels;
            }
        }, [])))
        : [];
};
exports.getMasterDealerChannels = getMasterDealerChannels;
var getChannelOptions = function () {
    return Object.keys(interfaces_1.DealerChannel).map(function (key) { return ({
        value: interfaces_1.DealerChannel[key],
        label: key,
    }); });
};
exports.getChannelOptions = getChannelOptions;
var getMasterDealerIds = function (associatedMasterDealers) {
    return Array.isArray(associatedMasterDealers)
        ? Array.from(new Set(associatedMasterDealers.reduce(function (ids, associatedMasterDealer) {
            if (Array.isArray(associatedMasterDealer === null || associatedMasterDealer === void 0 ? void 0 : associatedMasterDealer.masterDealer)) {
                return (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], ids, true), associatedMasterDealer.masterDealer.reduce(function (_ids_, masterDealer) {
                    if (!!(masterDealer === null || masterDealer === void 0 ? void 0 : masterDealer.masterDealerId)) {
                        _ids_.push(masterDealer === null || masterDealer === void 0 ? void 0 : masterDealer.masterDealerId);
                    }
                    return _ids_;
                }, []), true);
            }
            else {
                return ids;
            }
        }, [])))
        : [];
};
exports.getMasterDealerIds = getMasterDealerIds;
var getChannelSelectItems = function () {
    return (0, exports.getChannelOptions)().map(function (item) { return ({
        id: item.value.toLowerCase(),
        value: item.label.toUpperCase(),
    }); });
};
exports.getChannelSelectItems = getChannelSelectItems;
