import { Spinner } from '@monorepo/components'
import {
  ProductHelper,
  CartHelper,
  EventHelper,
  useChannel,
  formatCurrency,
} from '@monorepo/infra'
import { CartEvent, CartItems, CartProduct } from '@monorepo/interfaces'
import React, { useEffect, useState } from 'react'
import * as S from '../styles'

interface SectionCheckoutCoopProps {
  items: CartItems
  masterDealerId: string
  onUseCoop: (coopAmount: number, isCoopRequest: boolean) => void
  onIgnoreCoop: (coopAmount: number, isCoopRequest: boolean) => void
}

export const SectionCheckoutCoop: React.FC<SectionCheckoutCoopProps> = ({
  items,
  masterDealerId,
  onUseCoop,
  onIgnoreCoop,
}) => {
  const { coop, hasCoop } = useChannel()
  const [coopSubtotal, setCoopSubtotal] = useState(0)
  const [loadingCoopAmountsForCart, setLoadingCoopAmountsForCart] =
    useState(false)
  const [cartItemsWithCoop, setCartItemsWithCoop] = useState<CartItems>(items)

  const subtotal = CartHelper.getSubtotal(items)

  useEffect(() => {
    if (!coop?.availableBalance || CartHelper.isEmpty(items)) {
      setCartItemsWithCoop(items)
      return
    }

    setLoadingCoopAmountsForCart(true)
    CartHelper.getCoopTotalForCartV2(masterDealerId, items)
      .then((cartItemsWithCoop) => {
        setCartItemsWithCoop(cartItemsWithCoop)

        const coopTotal = CartHelper.getCoopUsedTotalForCart(cartItemsWithCoop)

        setCoopSubtotal(coopTotal)

        if (coopTotal > coop.availableBalance) {
          onIgnoreCoop(0, false)
        }
      })
      .catch(() => {
        setCoopSubtotal(0)
        onIgnoreCoop(0, false)
      })
      .finally(() => {
        setLoadingCoopAmountsForCart(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coop.availableBalance, items, masterDealerId])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getAmountAfterCoopText = (item: any) => {
    return `$${formatCurrency(
      Math.round(CartHelper.getCartItemTotalAfterCoop(item) * 100).toString()
    )}`
  }

  return (
    <S.Container>
      <S.ProductList>
        {cartItemsWithCoop.products.map((item: CartProduct, index: number) => {
          const {
            selectedVariantIndex,
            selectedDeliveryMethodIndex,
            selectedPrintAndShipIndex,
          } = item
          const variant = item.product.variants[selectedVariantIndex]
          return (
            <S.ProductItem key={`product-item-${index}`}>
              <S.ThumbnailContainer>
                <S.Thumbnail src={variant.media[0]} alt={item.product.title} />
              </S.ThumbnailContainer>
              <S.ProductDetail>
                <S.ProductRow>
                  <S.ProductTitle>{item.product.title}</S.ProductTitle>
                  <S.Text alignSelf="flex-start">
                    {`$${variant.printAndShipOptions[selectedPrintAndShipIndex]?.price}`}
                  </S.Text>
                </S.ProductRow>
                {coop?.availableBalance &&
                  (!loadingCoopAmountsForCart ? (
                    CartHelper.getCartItemCoopUsed(item) > 0 && (
                      <>
                        <S.ProductRow>
                          <S.ProductTitle>CO-OP %</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {CartHelper.getCartItemCoopUsedPercentageText(item)}
                          </S.Text>
                        </S.ProductRow>
                        <S.ProductRow>
                          <S.ProductTitle>AMOUNT AFTER CO-OP</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {getAmountAfterCoopText(item)}
                          </S.Text>
                        </S.ProductRow>
                      </>
                    )
                  ) : (
                    <S.ProductRow>
                      <Spinner spinnerSize={4} />
                    </S.ProductRow>
                  ))}
                <S.Text color={'#9fa8b3'}>
                  {ProductHelper.getDeliveryMethod(
                    item.product.deliveryMethods[
                      selectedDeliveryMethodIndex - 1
                    ],
                    variant.printAndShipOptions[selectedPrintAndShipIndex]
                      ?.pieceCount
                  )}
                </S.Text>
                <S.Text color={'#9fa8b3'}>
                  {ProductHelper.getVariantTitle(variant)}
                </S.Text>
              </S.ProductDetail>
              <S.Line />
            </S.ProductItem>
          )
        })}
        {cartItemsWithCoop.events.map((item: CartEvent, index: number) => {
          const { event } = item
          return (
            <S.ProductItem key={`event-item-${index}`}>
              <S.ThumbnailContainer>
                <S.Thumbnail
                  src={`${process.env.PUBLIC_URL}${EventHelper.getImageFile(
                    event.categories[0]
                  )}`}
                  alt={event.title}
                />
              </S.ThumbnailContainer>
              <S.ProductDetail>
                <S.ProductRow>
                  <S.ProductTitle>{item.event.title}</S.ProductTitle>
                  <S.Text alignSelf="flex-start">{`$${event.price}`}</S.Text>
                </S.ProductRow>
                {coop?.availableBalance &&
                  (!loadingCoopAmountsForCart ? (
                    CartHelper.getCartItemCoopUsed(item) > 0 && (
                      <>
                        <S.ProductRow>
                          <S.ProductTitle>CO-OP %</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {CartHelper.getCartItemCoopUsedPercentageText(item)}
                          </S.Text>
                        </S.ProductRow>
                        <S.ProductRow>
                          <S.ProductTitle>AMOUNT AFTER CO-OP</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {getAmountAfterCoopText(item)}
                          </S.Text>
                        </S.ProductRow>
                      </>
                    )
                  ) : (
                    <S.ProductRow>
                      <Spinner spinnerSize={4} />
                    </S.ProductRow>
                  ))}
                <S.Text color={'#9fa8b3'}>
                  {EventHelper.getLocation(event)}
                </S.Text>
              </S.ProductDetail>
              <S.Line />
            </S.ProductItem>
          )
        })}
      </S.ProductList>
      <S.ProductRow>
        <S.ProductTitle>Subtotal</S.ProductTitle>
        <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
      </S.ProductRow>
      {coop?.availableBalance && (
        <S.ProductRow>
          <S.ProductTitle>Co-Op dollars to apply</S.ProductTitle>
          {loadingCoopAmountsForCart ? (
            <Spinner spinnerSize={4} />
          ) : (
            <S.Text alignSelf="flex-start">${coopSubtotal.toFixed(2)}</S.Text>
          )}
        </S.ProductRow>
      )}
      <S.ProductRow>
        <S.ProductTitle>Total</S.ProductTitle>
        {coop.availableBalance ? (
          <S.Text alignSelf="flex-start">
            ${CartHelper.getSubtotalAfterCoop(cartItemsWithCoop).toFixed(2)}
          </S.Text>
        ) : (
          <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
        )}
      </S.ProductRow>
      <S.ProductRow style={{ gap: '15px' }}>
        <S.Button
          onClick={() => {
            onIgnoreCoop(0, false)
          }}
          isSecondary={true}
          disabled={loadingCoopAmountsForCart}
        >
          Continue with Credit Card Checkout
        </S.Button>
        {!hasCoop && coop.availableBalance && (
          <S.Button
            onClick={() => onUseCoop(coopSubtotal, true)}
            disabled={loadingCoopAmountsForCart}
          >
            Request Co-Op Funds
          </S.Button>
        )}
        {hasCoop && coop.availableBalance && (
          <S.Button
            onClick={() => {
              onUseCoop(coopSubtotal, false)
            }}
            disabled={loadingCoopAmountsForCart}
          >
            Apply Co-Op and Proceed
          </S.Button>
        )}
      </S.ProductRow>
    </S.Container>
  )
}
