import React from 'react'
import * as S from './styles'
import { Hero as HeroCrative } from '../../organisms'
import Hero from '../../../assets/media/close-up-of-stylish-composition-with-businessman-2022-12-07-04-22-55-utcArtboard-1.jpeg'
import HeroMobile from '../../../assets/media/hero-mobile.png'
import paidSocialMedia from '../../../assets/media/DTV-Social-Campaign-1.png'
import printCampaign from '../../../assets/media/DTV-Flyers-1.jpeg'
import bannerAds from '../../../assets/media/13Artboard-1-copy-100.png'
import radioCampaign from '../../../assets/media/young-radio-host-at-the-radio-broadcasting-station-2022-10-21-19-43-13-utc.jpeg'
import oohCampaign from '../../../assets/media/poster-mockup-inside-of-the-bus-stop-2021-09-04-14-37-18-utc.png'

type MediaProps = {
  products: {
    title: string
    images: string[]
    link: string
    isNew: boolean
    subtitle: string
    aditionalInfo: string
    showInfo?: boolean
  }[]
}

const Media: React.FC<MediaProps> = ({ products }) => {
  return (
    <main>
      <HeroCrative
        background={{ desk: Hero, mobile: HeroMobile }}
        title="DRIVE LEADS THROUGH MEDIA"
        description="Bring your message directly to your customers with paid media solutions that drive leads."
        icon={{ icon: 'DottedArrowRight', color: 'yellow', size: 60 }}
        flexFlow="row"
      />
      <S.HowItWorks>
        <S.HeaderAndTitle>
          <S.TitleH2>HOW IT WORKS</S.TitleH2>
          <S.Subtitle>
            While each media tactic is a bit different, the process for ordering
            a paid campaign is consistent. The following outlines the typical
            process you can expect when ordering a paid media campaign through
            tMZ.
          </S.Subtitle>
        </S.HeaderAndTitle>
        <S.Steps>
          <S.Step>
            <S.StepTitle>
              <span className="stepNumber">1</span>CHOOSE A PAID MEDIA SOLUTION
            </S.StepTitle>
            <S.Line />
            <S.StepContent>
              Select a paid media solution and place your order. A Marketing
              Specialist will be in contact with you to collect all details for
              your campaign.
            </S.StepContent>
          </S.Step>
          <S.Step>
            <S.StepTitle>
              <span className="stepNumber">2</span>TIME FOR KICKOFF
            </S.StepTitle>
            <S.Line />
            <S.StepContent>
              A kickoff call will be set to review all final campaign details
              prior to launch.
            </S.StepContent>
          </S.Step>
          <S.Step>
            <S.StepTitle>
              <span className="stepNumber">3</span>CAMPAIGN LAUNCH
            </S.StepTitle>
            <S.Line />
            <S.StepContent>
              The campaign is finalized and launched! If running a social media
              or digital display campaign, reporting will be provided as well as
              ongoing optimizations.
            </S.StepContent>
          </S.Step>
        </S.Steps>
      </S.HowItWorks>
      <S.HowItWorks>
        <S.HeaderAndTitle>
          <S.TitleH2>MEDIA SOLUTIONS</S.TitleH2>
          <S.Subtitle>
            Please click on the selected Paid Media Solution and place an order.
          </S.Subtitle>
        </S.HeaderAndTitle>
      </S.HowItWorks>
      <S.CardContainer>
        <S.CustomLink
          to={`/all-products/details/${
            products.find(
              (x) => x.title.indexOf('Paid Social Media Campaign') !== -1
            )?.link
          }`}
        >
          <S.MediaCard bgImage={paidSocialMedia}>
            <div>
              <p>PAID SOCIAL MEDIA</p>
            </div>
          </S.MediaCard>
        </S.CustomLink>
        <S.CustomLink
          to={`/all-products/details/${
            products.find((x) => x.title.indexOf('Print Campaign') !== -1)?.link
          }`}
        >
          <S.MediaCard bgImage={printCampaign}>
            <div>
              <p>PRINT CAMPAIGN</p>
            </div>
          </S.MediaCard>
        </S.CustomLink>
        <S.CustomLink
          to={`/all-products/details/${
            products.find((x) => x.title.indexOf('Radio Campaign') !== -1)?.link
          }`}
        >
          <S.MediaCard bgImage={radioCampaign}>
            <div>
              <p>RADIO CAMPAIGN</p>
            </div>
          </S.MediaCard>
        </S.CustomLink>
        <S.CustomLink
          to={`/all-products/details/${
            products.find(
              (x) => x.title.indexOf('3 Month Digital Display Campaign') !== -1
            )?.link
          }`}
        >
          <S.MediaCard bgImage={bannerAds}>
            <div>
              <p>BANNER ADS</p>
            </div>
          </S.MediaCard>
        </S.CustomLink>
        <S.CustomLink
          to={`/all-products/details/${
            products.find((x) => x.title.indexOf('Out of Home Campaign') !== -1)
              ?.link
          }`}
        >
          <S.MediaCard bgImage={oohCampaign}>
            <div>
              <p>OOH CAMPAIGN</p>
            </div>
          </S.MediaCard>
        </S.CustomLink>
      </S.CardContainer>
    </main>
  )
}

export default Media
