"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryGetPrice = exports.getChannels = exports.getVendor = exports.getProductTypeDescription = exports.getProductTypeOptions = exports.getSizeOptions = exports.getTitleFromSize = exports.getVariantTitle = exports.getDeliveryMethod = void 0;
var tslib_1 = require("tslib");
var interfaces_1 = require("@monorepo/interfaces");
var utils_1 = require("../utils");
var getDeliveryMethod = function (method, quantity) {
    if (quantity === void 0) { quantity = 0; }
    switch (method) {
        case 1:
            return "Print & Ship ".concat(quantity, " count");
        case 2:
            return 'Digital Download';
        case 3:
            return 'Custom Quote';
        case 4:
            return 'Place with Agency';
        default:
            return 'Unknown';
    }
};
exports.getDeliveryMethod = getDeliveryMethod;
var getVariantTitle = function (variant) {
    if (variant.title)
        return variant.title;
    if ((0, exports.getTitleFromSize)(variant.size) !== '')
        return (0, exports.getTitleFromSize)(variant.size);
    return 'Unknown Size';
};
exports.getVariantTitle = getVariantTitle;
var getTitleFromSize = function (size) {
    switch (size) {
        case 1:
            return '8.5" x 11" FSI';
        case 2:
            return '8.5" x 11" Flyer';
        case 3:
            return '8.5" x 5.5" Half Page';
        case 4:
            return '4" x 11" Doorhanger';
        case 5:
            return '8.5" x 5.5" Postcard';
        case 6:
            return '24" x 36" Poster';
        case 7:
            return '24" x 36" Sandwich Board';
        case 8:
            return '8.25" x 3.5" Bill Insert';
        case 9:
            return '24" x 18" Yard Sign';
        case 10:
            return 'Banner Ads';
        case 11:
            return '1080px x 1080px Social Posts';
        case 12:
            return '3.66"" x 8.5" Tri-fold Brochure';
        case 13:
            return '3.5" x 2" Business Card';
        case 14:
            return '3.8" x 2.12" Name Badges';
        case 15:
            return '8.5" x 11" Multi Page 4pg';
        case 16:
            return '8.5" x 11" Multi Page 8pg';
        case 17:
            return '8.5" x 11" Multi Page 16pg';
        case 18:
            return 'Custom Size';
    }
    return '';
};
exports.getTitleFromSize = getTitleFromSize;
var getSizeOptions = function () {
    var sizes = [
        { value: (0, exports.getTitleFromSize)(1), id: '1' },
        { value: (0, exports.getTitleFromSize)(2), id: '2' },
        { value: (0, exports.getTitleFromSize)(3), id: '3' },
        { value: (0, exports.getTitleFromSize)(4), id: '4' },
        { value: (0, exports.getTitleFromSize)(5), id: '5' },
        { value: (0, exports.getTitleFromSize)(6), id: '6' },
        { value: (0, exports.getTitleFromSize)(7), id: '7' },
        { value: (0, exports.getTitleFromSize)(8), id: '8' },
        { value: (0, exports.getTitleFromSize)(9), id: '9' },
        { value: (0, exports.getTitleFromSize)(10), id: '10' },
        { value: (0, exports.getTitleFromSize)(11), id: '11' },
        { value: (0, exports.getTitleFromSize)(12), id: '12' },
        { value: (0, exports.getTitleFromSize)(13), id: '13' },
        { value: (0, exports.getTitleFromSize)(14), id: '14' },
        { value: (0, exports.getTitleFromSize)(15), id: '15' },
        { value: (0, exports.getTitleFromSize)(16), id: '16' },
        { value: (0, exports.getTitleFromSize)(17), id: '17' },
        { value: (0, exports.getTitleFromSize)(18), id: '18' },
    ];
    return sizes;
};
exports.getSizeOptions = getSizeOptions;
var getProductTypeOptions = function () { return [
    { value: interfaces_1.ProductType.CREATIVE.toString(), label: 'Creative' },
    { value: interfaces_1.ProductType.CREATIVE_CUSTOM.toString(), label: 'Media' },
    { value: interfaces_1.ProductType.EVENT.toString(), label: 'Event' },
    { value: interfaces_1.ProductType.MICROSITE.toString(), label: 'Microsite' },
]; };
exports.getProductTypeOptions = getProductTypeOptions;
var getProductTypeDescription = function (type) {
    var option = (0, exports.getProductTypeOptions)().find(function (item) { return item.value === type.toString(); });
    return option === null || option === void 0 ? void 0 : option.label;
};
exports.getProductTypeDescription = getProductTypeDescription;
var getVendor = function (value) {
    var vendor = (0, utils_1.isNumber)(value)
        ? value
        : (0, utils_1.isString)(value)
            ? parseInt(value.toString(), 10)
            : undefined;
    return !isNaN(vendor) && vendor >= interfaces_1.Vendor.PURE && vendor <= interfaces_1.Vendor.DSI
        ? vendor
        : undefined;
};
exports.getVendor = getVendor;
var getChannels = function (products) {
    if (!products) {
        return undefined;
    }
    if (!Array.isArray(products)) {
        products = [products];
    }
    return Array.from(new Set(products.reduce(function (_channels_, product) {
        return Array.isArray(product === null || product === void 0 ? void 0 : product.channels)
            ? (0, tslib_1.__spreadArray)((0, tslib_1.__spreadArray)([], _channels_, true), product === null || product === void 0 ? void 0 : product.channels.filter(function (channel) { return !!channel; }).map(function (channel) { return channel.toLocaleLowerCase(); }), true) : _channels_;
    }, [])));
};
exports.getChannels = getChannels;
var tryGetPrice = function (priceStr) {
    if (!priceStr || priceStr.trim().length < 1) {
        return null;
    }
    try {
        return parseFloat(priceStr);
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
exports.tryGetPrice = tryGetPrice;
