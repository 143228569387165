import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import { Spinner } from '@monorepo/components'
import { OrderHelper, OrderStatus, roundCurrency } from '@monorepo/infra'
import { OrderItem } from '@monorepo/interfaces'
import * as S from '../styles'

interface SectionCheckoutCoopProps {
  items: OrderItem[]
  loadingCoop: boolean
  coopSubtotal: number
  onUseCoop: (coopAmount: number, isCoopRequest: boolean) => void
  onIgnoreCoop: (coopAmounts: number) => void
  status?: number
  approvedCoopRequest: boolean
  showCoopSummary: boolean
  canRequestCoop: boolean
}

export const SectionCheckoutCoop: React.FC<SectionCheckoutCoopProps> = ({
  items,
  loadingCoop,
  coopSubtotal,
  onUseCoop,
  onIgnoreCoop,
  status,
  approvedCoopRequest = false,
  showCoopSummary = true,
  canRequestCoop = false,
}) => {
  const subtotal = OrderHelper.getItemsSubtotal(items)
  // payment total with coop
  const difference = roundCurrency(subtotal - coopSubtotal)
  const coopPercentage = roundCurrency((coopSubtotal / subtotal) * 100)

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)

  const handleContinueWithCreditCardCheckoutClick = () => {
    if (status === OrderStatus.COOP_REQUEST) {
      setConfirmationDialogOpen(true)
    } else {
      onIgnoreCoop(0)
    }
  }

  const handleConfirmationDialogClose = (isConfirmed = false) => {
    setConfirmationDialogOpen(false)

    if (!isConfirmed) {
      return
    }

    onIgnoreCoop(0)
  }

  return (
    <S.Container>
      <S.ProductList>
        {items.map((item: OrderItem, index: number) => {
          const { product } = item
          return (
            <S.ProductItem key={`product-item-${index}`}>
              <S.ThumbnailContainer>
                <S.Thumbnail src={product.thumbnailUrl} alt={product.title} />
              </S.ThumbnailContainer>
              <S.ProductDetail>
                <S.ProductRow>
                  <S.ProductTitle>{product.title}</S.ProductTitle>
                  <S.Text alignSelf="flex-start">{`$ ${roundCurrency(
                    parseFloat(item.price) * item.quantity
                  )}`}</S.Text>
                </S.ProductRow>
              </S.ProductDetail>
              <S.Line />
            </S.ProductItem>
          )
        })}
      </S.ProductList>
      <S.ProductRow>
        <S.ProductTitle>Subtotal</S.ProductTitle>
        <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
      </S.ProductRow>
      {showCoopSummary && (
        <>
          <S.ProductRow>
            <S.ProductTitle>Co-Op %</S.ProductTitle>
            {loadingCoop ? (
              <Spinner spinnerSize={4} />
            ) : (
              <S.Text alignSelf="flex-start">
                {coopPercentage.toFixed(2)}%
              </S.Text>
            )}
          </S.ProductRow>
          <S.ProductRow>
            <S.ProductTitle>
              {approvedCoopRequest ? 'Co-Op' : 'Co-Op dollars to apply'}
            </S.ProductTitle>
            {loadingCoop ? (
              <Spinner spinnerSize={4} />
            ) : (
              <S.Text alignSelf="flex-start">${coopSubtotal.toFixed(2)}</S.Text>
            )}
          </S.ProductRow>
        </>
      )}
      <S.ProductRow>
        <S.ProductTitle>
          {approvedCoopRequest ? 'Dealer spend' : 'Total'}
        </S.ProductTitle>
        <S.Text alignSelf="flex-start">${difference.toFixed(2)}</S.Text>
      </S.ProductRow>
      <S.ProductRow style={{ gap: '15px' }}>
        {!approvedCoopRequest ? (
          <S.Button
            onClick={handleContinueWithCreditCardCheckoutClick}
            isSecondary={true}
            disabled={loadingCoop}
          >
            Continue with Credit Card Checkout
          </S.Button>
        ) : undefined}
        {status !== OrderStatus.COOP_REQUEST && canRequestCoop && (
          <S.Button
            onClick={() => onUseCoop(coopSubtotal, true)}
            disabled={loadingCoop}
          >
            Request Co-Op Funds
          </S.Button>
        )}
        {status !== OrderStatus.COOP_REQUEST &&
        showCoopSummary &&
        coopSubtotal > 0 ? (
          <S.Button
            onClick={() => onUseCoop(coopSubtotal, false)}
            disabled={loadingCoop}
          >
            Apply Co-Op and Proceed
          </S.Button>
        ) : (
          ''
        )}
      </S.ProductRow>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => handleConfirmationDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ zIndex: 9999 }}
      >
        <DialogTitle id="alert-dialog-title">
          {'Continue with Credit Card Checkout'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Co-Op request NOT approved. Do you want to proceed with a credit
            card payment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmationDialogClose(false)}>
            No
          </Button>
          <Button onClick={() => handleConfirmationDialogClose(true)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </S.Container>
  )
}
